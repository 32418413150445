import React from "react"
import styled from "styled-components"
import theme from "@assets/styles/theme"
import kebabCase from "lodash/kebabCase"

import AppLayout from "@components/layout/App"
import CAREER_LEVELS_CONTENT from "@content/career-levels.js"

import UnstyledLink from "@components/elements/UnstyledLink"

import { getCareerBandsComparisonUrl } from "@helpers/url"
import HeaderBg from "@assets/career-levels/banner_career_bands_levels.jpg"
import { Box } from "@material-ui/core"

const PageContainer = styled.div`
  padding: 40px 8px;

  @media (max-width: 1023px) {
    padding: 24px 8px;
  }
`

const OverflowContainer = styled.div`
  padding-bottom: 16px;
  overflow-x: scroll;
  overflow-y: hidden;

  @media (max-width: 1023px) {
    display: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`

const PAGE_HEADER_HEIGHT = "212px"
const PAGE_GREY_HEIGHT = "132px"

const PageHeader = styled.div`
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position: center;
  height: ${PAGE_HEADER_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const PageHeaderBoxes = styled.div`
  display: flex;
  flex: 1;
  align-items: flex;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 50;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderGrey = styled.div`
  background-color: #fff;
  opacity: 80%;
  color: ${theme.colors.grey.white};
  flex: 1;
  height: ${PAGE_GREY_HEIGHT};

  overflow: hidden;

  @media (max-width: 1023px) {
    background-color: transparent;
    padding: 0;
  }
`

const PageHeaderYellow = styled.div`
  width: 40%;
  height: 60px;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderTextContainer = styled.div`
  color: ${theme.colors.grey.darkGray};
  width: min(1140px, calc(100vw - 32px));
  padding: 24px 0;
  z-index: 2;
  height: ${PAGE_GREY_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 0;
    height: auto;
  }
`

const PageHeaderH2 = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 0 8px 0;
  max-width: 50%;
  color: inherit;
  @media (max-width: 1023px) {
    color: white;
    font-size: 18px;
    line-height: 21px;
    margin: 30px 0;
    max-width: none;
  }
`

const PageHeaderBody = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;

  @media (max-width: 1023px) {
    padding: 12px 18px;
    font-size: 12px;
    line-height: 20px;
    background-color: ${theme.colors.grey.greyBackground};
    color: ${theme.colors.grey.textDark};
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 0;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding-bottom: 24px;
  }
`

const CareerBandText = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;

  padding: 0 24px 12px;

  @media (max-width: 1023px) {
    padding: 0 16px 12px;

    text-align: center;
    margin-right: 0;
    font-size: 14px;
  }
`

const CAREER_BANDS = CAREER_LEVELS_CONTENT

export default function CareerBandsPage() {
  return (
    <AppLayout>
      <PageHeader>
        <PageHeaderBoxes>
          <PageHeaderGrey />
          <PageHeaderYellow />
        </PageHeaderBoxes>
        <PageHeaderTextContainer>
          <PageHeaderH2>Career Bands & Levels</PageHeaderH2>
          <PageHeaderBody>
            Click on any level within the career bands to view criteria
          </PageHeaderBody>
        </PageHeaderTextContainer>
      </PageHeader>
      <PageContainer>
        <OverflowContainer>
          {CAREER_BANDS.map(careerBand => {
            return (
              <Box
                padding={1}
                bgcolor={careerBand.bgColor}
                display="flex"
                alignItems="center"
                flexDirection="column"
                width={{ xs: "300px", md: "400px" }}
                key={careerBand.name}
                mb={2}
                marginLeft={{ xs: 0, md: careerBand.marginLeft }}
              >
                <CareerBandText style={{ color: careerBand.textColor }}>
                  {careerBand.name}
                </CareerBandText>
                <Box
                  bgcolor="white"
                  display="flex"
                  width="100%"
                  justifyContent="center"
                  pt={1}
                >
                  {careerBand.bands.map(level => {
                    return (
                      <UnstyledLink
                        to={getCareerBandsComparisonUrl(
                          kebabCase(careerBand.name),
                          level.code
                        )}
                        key={level.code}
                      >
                        <CareerBandText>{level.code}</CareerBandText>
                      </UnstyledLink>
                    )
                  })}
                </Box>
              </Box>
            )
          })}
        </OverflowContainer>
      </PageContainer>
    </AppLayout>
  )
}
