export default [
  {
    name: "Executive",
    slug: "executive",
    bgColor: "#006EB3",
    marginLeft: "900px",
    prePad: 6,
    postPad: 0,
    textColor: "#fff",
    bands: [
      {
        code: "E0",
        headers: [
          {
            header: "General Profile",
            content: [
              "Provides leadership and direction through managers and senior managers",
              "Is accountable for the performance and results of a major part of the organization (organization function or significant operational segment within a business unit, or an enterprise-wide organization sub-function)",
              "Develops and executes strategy to achieve key business objectives",
              "Decisions are guided by company and functional strategy and objectives",
            ],
          },
          {
            header: "Business Acumen",
            content: [
              "Applies in-depth knowledge of own organization function or major operational segment as well as business and industry expertise to ensure long term growth of the organization",
            ],
          },
          {
            header: "Leadership Impact",
            content: [
              "Leads a major part of the organization (organization function or significant operational segment within a business unit, or an enterprise-wide organization sub-function), develops and executes long-term functional strategy to achieve key business objectives",
              "Guided by business unit and/or organization functional strategy, impacts results of a  business unit, major operational segment or enterprise-wide organization sub-function",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Evaluates key business challenges; directs the development of new or innovative solutions",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Establishes collaborative relationships with other senior leaders across the business and with external organizations",
            ],
          },
        ],
      },
      {
        code: "E1",
        headers: [
          {
            header: "General Profile",
            content: [
              "Has primary accountability for the financial performance of a support function or non-core business unit",
              "Develops and executes function or business strategy",
              "Influences the allocation of resources across multiple divisions or sub-functions/functions",
            ],
          },
          {
            header: "Business Acumen",
            content: [
              "Applies in-depth knowledge of how own area of responsibility  integrates with other businesses across the company and how the company differentiates itself from competitors to create competitive advantage",
            ],
          },
          {
            header: "Leadership Impact",
            content: [
              "Has full leadership responsibility for a company-wide support function or non-core business unit; develops the strategic plans for own organizational entity",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Evaluates key business and organizational challenges; directs the development of new or innovative solutions",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Influences and negotiates, internally and externally, with great latitude on outcomes",
            ],
          },
        ],
      },
      {
        code: "E2",
        headers: [
          {
            header: "General Profile",
            content: [
              "Has primary accountability for the financial performance of a strategic function or core business unit",
              "Develops and executes strategic plan for own area and shapes the company strategy",
              "Has significant impact on organizational or business performance",
            ],
          },
          {
            header: "Business Acumen",
            content: [
              "Applies in-depth knowledge of the economic, commercial and political issues that impact company and industry performance; anticipates industry trends",
            ],
          },
          {
            header: "Leadership Impact",
            content: [
              "Has full leadership responsibility for  a major segment of the organization (core business unit, strategic function or geography); shapes the company strategy",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Directs the development of new or innovative solutions that optimize organizational and business performance",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Influences a wide range of audiences and establishes collaborative relationships within the company or with external organizations; presents and defends complicated or delicate issues that have an important impact on business success",
            ],
          },
        ],
      },
      {
        code: "E3",
        headers: [
          {
            header: "General Profile",
            content: [
              "Establishes the overall strategic direction of the company as a member of the executive leadership team",
              "Is accountable for long-range planning and major initiatives of the company",
              "Has direct and lasting impact on overall corporate performance and image",
            ],
          },
          {
            header: "Business Acumen",
            content: [
              "Applies a comprehensive understanding of the enterprise and  the economic, commercial, political issues to maximize company performance",
            ],
          },
          {
            header: "Leadership Impact",
            content: [
              "Serves with peers on the executive leadership team to set the overall strategic direction of the company: has direct and lasting impact on the company’s operational and financial performance",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Directs the development of new and uncharted solutions and drives business direction to establish desired position in the market",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Influences and shapes the image of the company; presents and defends complicated or delicate issues that have a lasting impact on the business",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Management",
    slug: "management",
    bgColor: "#82ABC1",
    marginLeft: "650px",
    prePad: 4,
    postPad: 2,
    textColor: "#363636",
    bands: [
      {
        code: "M1",
        headers: [
          {
            header: "General Profile",
            content: [
              "Supervises the daily activities of support or operations team members",
              "Sets priorities for the team to ensure task completion; coordinates work activities with other supervisors",
              "Problem solving is guided by policies and procedures; receives guidance and oversight from manager",
              "Does not typically perform the work supervised",
            ],
          },
          {
            header: "Business Acumen",
            content: [
              "Applies operational business practices and coordinates with other closely related areas to improve efficiency",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Has formal supervisory responsibilities; coordinates resources and sets daily priorities to meet operational objectives",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Uses judgment to identify, troubleshoot and resolve day-to-day technical and operational problems",
            ],
          },
          {
            header: "Impact",
            content: [
              "Guided by policies and procedures, impacts the quality, efficiency and effectiveness of own team and its contribution to the department",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Explains  information, conveys performance expectations and handles sensitive issues",
            ],
          },
        ],
      },
      {
        code: "M2",
        headers: [
          {
            header: "General Profile",
            content: [
              "Manages professional employees and/or supervisors",
              "Has accountability for the performance and results of a team within own area of specialty",
              "Adapts departmental plans and priorities to address resource and operational challenges",
              "Decisions and problem solving are guided by policies, procedures and department plan; receives guidance from manager",
              "Provides technical guidance to employees, colleagues and/or customers",
            ],
          },
          {
            header: "Business Acumen",
            content: [
              "Applies understanding of the business and how own area integrates with others  to achieve departmental objectives",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Manages one or more generally homogeneous teams; adapts department plans and priorities to meet short-term service and/or operational objectives",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Identifies and solves technical and operational problems; understands and recognizes broader impact across the department",
            ],
          },
          {
            header: "Impact",
            content: [
              "Guided by policies and departmental plan, impacts the team’s ability to achieve service, quality and timeliness of objectives",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Guides and influences  others either internally or externally to adopt a different point of view",
            ],
          },
        ],
      },
      {
        code: "M3",
        headers: [
          {
            header: "General Profile",
            content: [
              "Provides leadership to managers and professional staff",
              "Is accountable for the performance and results of multiple related teams",
              "Develops departmental plans, including business, operational and/or organizational priorities",
              "Decisions are guided by resource availability and organizational objectives",
            ],
          },
          {
            header: "Business Acumen",
            content: [
              "Applies knowledge of key business drivers and the factors that maximize department performance",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Manages multiple related teams, sets departmental priorities and allocates resources to align with business objectives and annual plan",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Identifies and solves complex, operational and organizational problems leveraging the appropriate resources within or outside the department",
            ],
          },
          {
            header: "Impact",
            content: [
              "Guided by organization functional business plans, impacts the department results by supporting and funding of projects, products, services and/or technologies",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Influences managers and leaders to take action and/or negotiates with external partners/vendors/customers",
            ],
          },
        ],
      },
      {
        code: "M4",
        headers: [
          {
            header: "General Profile",
            content: [
              "Provides leadership and direction through managers",
              "Is accountable for the performance and results of managers",
              "Executes business plans and contributes to the development of functional strategy",
              "Decisions are guided by functional or major operational segment strategy and priorities",
            ],
          },
          {
            header: "Business Acumen",
            content: [
              "Applies broad industry knowledge and commercial awareness to meet departmental performance and goals",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Leads through subordinate managers; contributes to the development of functional/operational long-term strategy and ensures alignment with company’s goals/objectives",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Directs the resolution of highly complex or unusual business problems applying advanced  critical thinking",
            ],
          },
          {
            header: "Impact",
            content: [
              "Guided by organization functional strategy, has broad impact on the departmental or functional, or divisional",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: ["Collaborates with senior leaders across the business"],
          },
        ],
      },
    ],
  },
  {
    name: "Professional",
    slug: "professional",
    bgColor: "#C8CBCE",
    marginLeft: "400px",
    prePad: 2,
    postPad: 3,
    textColor: "#363636",
    bands: [
      {
        code: "P1",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires conceptual knowledge of practices, and procedures within a job discipline  ​",
              "Performs routine assignments using existing procedures ​",
              "Receives instruction, guidance and direction from  more senior level roles​",
              "Entry level to a professional career progression",
            ],
          },
          {
            header: "Job Functional Knowledge",
            content: [
              "Requires conceptual knowledge of theories, practices and procedures within a job discipline",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Applies general knowledge of business developed through education or past experience",
            ],
          },
          {
            header: "Leadership",
            content: ["Accountable for own contributions"],
          },
          {
            header: "Problem Solving",
            content: [
              "Uses existing procedures to solve standard problems; analyzes information and standard practices to make judgments",
            ],
          },
          {
            header: "Impact",
            content: [
              "Has limited impact on own work team; works within standardized procedures and practices to achieve objectives and meet deadlines",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Exchanges straightforward information, asks questions and checks for understanding",
            ],
          },
        ],
      },
      {
        code: "P2",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires working knowledge and experience in own job discipline and broadens capabilities",
              "Continues to build knowledge of the company, processes and customers",
              "Performs a range of  assignments related to job discipline",
              "Uses prescribed guidelines or policies in analyzing situations",
              "Receives a moderate level of guidance and direction",
            ],
          },
          {
            header: "Job Functional Knowledge",
            content: [
              "Requires expanded conceptual knowledge in own job discipline and broadens capabilities",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Understands key business drivers; uses this understanding to accomplish own work",
            ],
          },
          {
            header: "Leadership",
            content: ["Provides informal guidance to new team members"],
          },
          {
            header: "Problem Solving",
            content: [
              "Solves problems in straightforward situations; analyzes possible solutions using technical experience and judgment and precedents",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts quality of own work and the work of others on the team; works within guidelines and policies",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Explains complex information to others in straightforward situations",
            ],
          },
        ],
      },
      {
        code: "P3",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires in-depth conceptual and practical knowledge in own job discipline and basic knowledge of related job disciplines",
              "Solves complex problems",
              "Works independently, receives minimal guidance",
              "May lead projects or project steps within a broader project or may have accountability for on-going activities or objectives",
              "Acts as a resource for colleagues with less experience",
              "Level at which career may stabilize for many years or until retirement",
            ],
          },
          {
            header: "Job Functional Knowledge",
            content: [
              "Requires in-depth conceptual and practical knowledge in own job discipline and basic knowledge of related job disciplines",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Has knowledge of best practices and how own area integrates with others; is aware of operational issues",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Acts as a resource for colleagues with less experience; may lead small projects with manageable risks and resource requirements",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Solves complex problems; takes a new perspective on existing solutions; exercises judgment based on the analysis of multiple sources of information",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts a range of customer, operational, project or service activities within own team and other related teams; works within broad guidelines and policies",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Explains difficult or sensitive information; works to build consensus",
            ],
          },
        ],
      },
      {
        code: "P4",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires specialized depth and/or breadth of expertise in own job discipline or field",
              "Leads others to solve complex problems",
              "Works independently, with guidance in only the most complex situations",
              "May lead functional teams or projects",
            ],
          },
          {
            header: "Job Functional Knowledge",
            content: [
              "Requires specialized depth and/or breadth of expertise in own job discipline or field",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Interprets internal/external business challenges and recommends best practices to improve products, processes or services",
            ],
          },
          {
            header: "Leadership",
            content: [
              "May lead functional teams or projects with moderate resource requirements, risk, and/or complexity",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Leads others to solve complex problems; uses sophisticated analytical thought to exercise judgement and identify innovative solutions",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the achievement of customer, operational, project or service objectives; work is guided by functional policies",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Communicates difficult concepts and negotiates with others to adopt a different point of view",
            ],
          },
        ],
      },
      {
        code: "P5",
        headers: [
          {
            header: "General Profile",
            content: [
              "Recognized as an expert within the company and requires in-depth and/or breadth of expertise in own job discipline and broad knowledge of other job disciplines within the organization function",
              "Solves unique problems that have a broad impact on the business",
              "Contributes to the development of organization functional strategy",
              "Progression to this level is typically restricted on the basis of business requirements",
            ],
          },
          {
            header: "Job Functional Knowledge",
            content: [
              "Regarded as the technical expert in their job discipline within the organization",
              "Requires in-depth and/or breadth of expertise in own job discipline and broad knowledge of other job disciplines within the organization function",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Anticipates business and regulatory issues; recommends product, process or service improvements",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Leads projects with notable risk and complexity; develops the strategy for project execution",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Solves unique problems with broad impact on the business; requires conceptual and innovative thinking to develop solutions",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the direction and resource allocation for program, project or services; works within general functional policies and industry guidelines",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Communicates complex ideas, anticipates potential objections and persuades others, often at senior levels, to adopt a different point of view",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Business Support",
    slug: "business-support",
    bgColor: "#8382A8",
    marginLeft: "150px",
    prePad: 0,
    postPad: 6,
    textColor: "#fff",
    bands: [
      {
        code: "B1",
        headers: [
          {
            header: "General Profile",
            content: [
              "Entry-level position with limited prior training or relevant work experience",
              "Acquires basic skills to perform routine tasks",
              "Work is prescribed and completed with little autonomy",
              "Works with either close supervision or under clearly defined procedures",
            ],
          },
          {
            header: "Job Functional Knowledge",
            content: ["Develops skills to perform basic activities in own job"],
          },
          {
            header: "Business Expertise",
            content: [
              "Understands how the assigned duties integrate with others in the team",
            ],
          },
          {
            header: "Leadership",
            content: ["Has no supervisory responsibilities"],
          },
          {
            header: "Problem Solving",
            content: [
              "Uses existing procedures to solve routine problems; has limited discretion",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the accuracy and quality of own work; receives close supervision; duties are clearly defined",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: ["Uses communication skills and common courtesy"],
          },
        ],
      },
      {
        code: "B2",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires knowledge and skills gained through formal training or considerable work experience",
              "Works within established procedures with a moderate degree of supervision",
            ],
          },
          {
            header: "Job Functional Knowledge",
            content: [
              "Requires established skills to perform a range of day-to-day activities",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Understands how the assigned duties relate to others in the team and how the team integrates with other teams",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Has no supervisory responsibilities; manages own workload",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Solves routine problems without supervisory approval; evaluates and selects solutions from established options",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts own team through the accuracy and quality of work; follows procedures and receives regular supervision",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: ["Uses communication skills to exchange information"],
          },
        ],
      },
      {
        code: "B3",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires specialized skills or is multi-skilled developed through job-related training and considerable on-the-job experience",
              "Completes work with a limited degree of supervision",
              "Likely to act as an informal resource for associates with less experience",
            ],
          },
          {
            header: "Job Functional Knowledge",
            content: [
              "Requires skilled in a range of processes, procedures and systems to carry out assigned tasks or has developed deep skills in a single area",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Applies knowledge of how the team integrates with other teams to achieve objectives",
            ],
          },
          {
            header: "Leadership",
            content: ["Provides informal guidance and support to team members"],
          },
          {
            header: "Problem Solving",
            content: [
              "Provides solutions to atypical problems based on proven practices or procedures",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the quality, timeliness and effectiveness of the team; recommends changes to improve efficiency",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: ["Explains job specific information"],
          },
        ],
      },
      {
        code: "B4",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires expertise in a variety of work processes through a combination of job-related training and considerable on-the-job experience",
              "Typically acts as a lead, coordinating the work of others but not a supervisor",
              "Works autonomously within established procedures and practices",
            ],
          },
          {
            header: "Job Functional Knowledge",
            content: [
              "Requires breadth and/or depth of skills in a range of processes, procedures and systems",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Requires in-depth knowledge of department processes and procedures",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Serves as lead for the team; allocates work and provides guidance to team members",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Gathers information to solve problems that are escalated from team members",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts own team and closely related teams; defines and recommend process improvements",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: ["Evaluates and communicates job-specific information"],
          },
        ],
      },
    ],
  },
]
